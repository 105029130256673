import React from 'react';
import { currentUrl } from '@avensia/scope';
import { replaceState } from '@avensia/scope';
import Select, { Variant } from 'Shared/Fields/Select';
import { Appearance } from 'Shared/Button';
import LinkIconTextBlockViewModel from 'CustomerService/LinkIconTextBlockViewModel.type';
import { styled } from '@glitz/react';
import { thin, alto } from 'Shared/Style';
import { pixelsToUnit } from 'Shared/Style/unit';

type OptionType = {
  text: string;
  value: string;
};

type ItemType = Scope.ContentAreaItem & LinkIconTextBlockViewModel;

type PropType = {
  items: Scope.ContentAreaItem[];
};

type StateType = {
  options: OptionType[];
  selectedValue: string;
};

class FaqPageSelect extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);

    this.state = {
      options: [],
      selectedValue: '',
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.items.length > 0) {
      this.props.items.forEach((item: ItemType, index: number) => {
        const { options } = this.state;
        const text = item.text;
        const value = item.url;
        options.push({ text, value });
        this.setState({ options });
        if (currentUrl().pathname === item.url) {
          this.setState({ selectedValue: item.url });
        }
      });
    }
  }

  render() {
    return (
      <SelectContent
        options={this.state.options}
        value={this.state.selectedValue}
        variant={Variant.Medium}
        onChangeOption={replaceState}
        appearance={[Appearance.Bare, Appearance.Full]}
      />
    );
  }
}

export default FaqPageSelect;

const SelectContent = styled(Select, {
  margin: {
    y: pixelsToUnit(20),
  },
  width: '100%',
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: alto,
    },
  },
});
