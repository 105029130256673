/**
 * @ComponentFor CustomerServiceFaqPageViewModel
 */

import React from 'react';
import { styled } from '@glitz/react';
import connect from 'Shared/connect';
import { Main, Basic, Appearance, Layout } from 'Shared/PageLayout/index';
import CustomerServiceFaqPageViewModelType from './CustomerServiceFaqPageViewModel.type';
import QuestionsBlock from './QuestionsBlock';
import { alpha, pixelsToUnit, minMediumMediaQuery } from 'Shared/Style';
import { epiPropertyValue } from '@avensia/scope-episerver';
import LinkBlockContentArea from 'CustomerService/components/LinkBlockContentArea';
import BreadCrumbs from 'Shared/Breadcrumbs';
import ContactUs from 'CustomerService/components/ContactUs';
import FaqMobileLinkBlock from 'CustomerService/components/FaqMobileLinkBlock';

type ConnectStateType = { currentBreakpoint: number };

type PropType = CustomerServiceFaqPageViewModelType & ConnectStateType;

const CustomerServiceFaqPage = (props: PropType) => {
  const {
    leftAreaQuestionBlock,
    rightAreaQuestionBlock,
    currentBreakpoint,
    breadCrumbs,
    heading,
    page,
    mainPage,
    contactUsHeader,
    contactUsText,
    contactUsEmail,
  } = props;
  const isMobile = currentBreakpoint < 4;

  return (
    <MainContent appearance={Appearance.Narrow}>
      <PaddedContentWrapper>
        <BreadCrumbsWrapper>
          <BreadCrumbs breadcrumbs={breadCrumbs} />
        </BreadCrumbsWrapper>
        <Heading>{heading}</Heading>
      </PaddedContentWrapper>
      {isMobile ? (
        <PaddedContentWrapper>
          <FaqMobileLinkBlock
            items={
              (epiPropertyValue(page.linkBlockHolderBlockArea) &&
                epiPropertyValue(page.linkBlockHolderBlockArea).items) ||
              (epiPropertyValue(mainPage.linkBlockHolderBlockArea) &&
                epiPropertyValue(mainPage.linkBlockHolderBlockArea).items) ||
              []
            }
          />
        </PaddedContentWrapper>
      ) : (
          <Basic>
            <LinkBlockContentArea
              items={
                epiPropertyValue(page.linkBlockHolderBlockArea)
                  ? page.linkBlockHolderBlockArea
                  : mainPage.linkBlockHolderBlockArea
              }
            />
          </Basic>
        )}
      <PaddedContentWrapper>
        {isMobile ? (
          <React.Fragment>
            {leftAreaQuestionBlock.length > 0 &&
              leftAreaQuestionBlock.map(({ title, questions }) => (
                <QuestionsBlock key={title + questions} heading={title} questions={questions} isMobile={isMobile} />
              ))}

            {rightAreaQuestionBlock.length > 0 &&
              rightAreaQuestionBlock.map(({ title, questions }) => (
                <QuestionsBlock key={title + questions} heading={title} questions={questions} isMobile={isMobile} />
              ))}
          </React.Fragment>
        ) : (
            <Basic layout={Layout.OneToOne}>
              {leftAreaQuestionBlock.length > 0 &&
                leftAreaQuestionBlock.map(({ title, questions }) => (
                  <QuestionsBlock key={title + questions} heading={title} questions={questions} isMobile={isMobile} />
                ))}

              {rightAreaQuestionBlock.length > 0 &&
                rightAreaQuestionBlock.map(({ title, questions }) => (
                  <QuestionsBlock key={title + questions} heading={title} questions={questions} isMobile={isMobile} />
                ))}
            </Basic>
          )}
        <ContactUs header={contactUsHeader} text={contactUsText} email={contactUsEmail} />
      </PaddedContentWrapper>
    </MainContent>
  );
};

export default connect(
  (state): ConnectStateType => ({
    currentBreakpoint: state.currentBreakpoint,
  }),
)(CustomerServiceFaqPage);

const MainContent = styled(Main, {
  paddingBottom: pixelsToUnit(100),
  maxWidth: pixelsToUnit(1400),
});

const BreadCrumbsWrapper = styled.div({
  textAlign: 'center',
  [minMediumMediaQuery]: {
    textAlign: 'left',
  },
});

const Heading = styled.h2({
  fontWeight: 'normal',
  textAlign: 'center',
  [minMediumMediaQuery]: {
    fontSize: alpha,
    textAlign: 'left',
  },
});

const PaddedContentWrapper = styled.div({
  padding: { x: pixelsToUnit(5) },
  [minMediumMediaQuery]: {
    padding: { x: pixelsToUnit(10) },
  },
});
