/**
 * @ComponentFor QuestionsBlock
 */

import React from 'react';
import { styled } from '@glitz/react';
import { epiPropertyValue, EpiProperty } from '@avensia/scope-episerver';
import QuestionsBlockType from './QuestionsBlock.type';
import H4 from 'Shared/Generic/H4';
import Add from 'Shared/Icon/Add';
import Accordion from 'Shared/Accordion';
import { pixelsToUnit, transition, minMediumMediaQuery } from 'Shared/Style';

type PropType = {
  blockID?: number;
  isMobile: boolean;
  questions: QuestionsBlockType[];
  heading?: string;
};

type StateType = {
  openGroupIndex?: number;
  openGroupIndexList?: number[];
};

class QuestionsBlock extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      openGroupIndex: null,
      openGroupIndexList: [],
    };
  }

  toggleAccordion = (groupIndex: number) => {
    let List = [...this.state.openGroupIndexList];
    if (!List.includes(groupIndex)) {
      List.push(groupIndex);
    } else {
      const index = List.indexOf(groupIndex);
      List.splice(index, 1);
    }
    this.setState({
      openGroupIndex: this.state.openGroupIndex !== groupIndex ? groupIndex : null,
      openGroupIndexList: List,
    });
  };

  renderAccordionHeader = (heading: string, groupIndex: number) => {
    return (
      <AccordionHeader>
        <Heading css={{ fontWeight: this.state.openGroupIndex === groupIndex ? 'bold' : 'normal' }}>{heading}</Heading>
        <ToggleIcon css={{ transform: this.state.openGroupIndex === groupIndex ? 'rotate(135deg)' : 'rotate(0)' }} />
      </AccordionHeader>
    );
  };

  render() {
    const { isMobile, questions, heading } = this.props;

    return (
      <div>
        <Title>{heading}</Title>
        {isMobile
          ? !!questions &&
            questions.map(({ header, text }, idx) => (
              <CustomAccordion
                body={
                  <AccordionBody>
                    <EpiProperty for={text} />
                  </AccordionBody>
                }
                key={idx}
                header={this.renderAccordionHeader(epiPropertyValue(header), idx)}
                // tslint:disable-next-line:jsx-no-lambda
                onToggle={() => this.toggleAccordion(idx)}
                isOpen={this.state.openGroupIndexList.includes(idx)}
              />
            ))
          : !!questions &&
            questions.map(({ header, text }, idx) => (
              <React.Fragment key={idx}>
                <H4>{epiPropertyValue(header)}</H4>
                <EpiProperty for={text} />
              </React.Fragment>
            ))}
      </div>
    );
  }
}

export default QuestionsBlock;

const AccordionHeader = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
});

const CustomAccordion = styled(Accordion, {
  backgroundColor: theme => theme.cardBackgroundColor,
  marginBottom: pixelsToUnit(10),
});

const Title = styled.h3({
  margin: {
    y: pixelsToUnit(17),
  },
  [minMediumMediaQuery]: {
    margin: {
      y: pixelsToUnit(20),
    },
  },
});

const Heading = styled.h5({
  textAlign: 'left',
  lineHeight: '3rem',
  marginLeft: pixelsToUnit(10),
});

const ToggleIcon = styled(Add, {
  flexShrink: 0,
  marginRight: pixelsToUnit(10),
  ...transition({
    property: 'all',
    duration: '.2s',
    willChange: true,
    timingFunction: 'ease',
  }),
});

const AccordionBody = styled.div({
  marginLeft: pixelsToUnit(10),
});
